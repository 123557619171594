import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class SettingsService extends BaseService<any> {
  get endpoint() {
    return "/settings";
  }

  async fetchOnePublic(id: string): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/public/${id}`);
    return response.data;
  }
}

export default new SettingsService();
