

























































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { NavItem } from "../shared/types/nav-item.class";
import { Watch } from "vue-property-decorator";

const authModule = namespace("Auth");
const featuresModule = namespace("Features");
const stylingModule = namespace("Styling");

@Component({})
export default class SideMenu extends Vue {
  moderatorPages: string[] = ["static-pages", "home-config"];
  navItems: NavItem[] = [
    new NavItem(
      this.$i18n.t("basic-info") as string,
      "basic-info",
      this.moderatorPages.includes("basic-info"),
      "home"
    ),
    new NavItem(
      this.$i18n.t("features") as string,
      "feature-config",
      this.moderatorPages.includes("features"),
      "toggle-on"
    ),
    new NavItem(
      this.$i18n.t("layout-and-pages") as string,
      "",
      this.moderatorPages.includes("layout-and-pages"),
      "",
      ""
    ),
    new NavItem(
      this.$i18n.t("home-page") as string,
      "home-config",
      this.moderatorPages.includes("home-config"),
      "pager"
    ),
    new NavItem(
      this.$i18n.t("header") as string,
      "header-config",
      this.moderatorPages.includes("header-config"),
      "bars"
    ),
    new NavItem(
      this.$i18n.t("static-pages") as string,
      "static-pages",
      this.moderatorPages.includes("static-pages"),
      "file-alt"
    ),
    new NavItem(
      this.$i18n.t("styling") as string,
      "style-config",
      this.moderatorPages.includes("style-config"),
      "palette"
    ),
    // new NavItem(
    //   this.$i18n.t("tags") as string,
    //   "tags-config",
    //   "tags"
    // ),
    new NavItem(
      this.$i18n.t("translations") as string,
      "translations-config",
      this.moderatorPages.includes("translations-config"),
      "language"
    ),
    new NavItem(
      this.$i18n.t("sponsors") as string,
      "sponsors-config",
      this.moderatorPages.includes("sponsors-config"),
      "handshake",
      "sponsors"
    ),
    new NavItem(
      this.$i18n.t("livestream-webinar") as string,
      "",
      this.moderatorPages.includes("livestream-webinar"),
      "",
      ""
    ),
    new NavItem(
      this.$i18n.t("livestreams") as string,
      "livestream-config",
      this.moderatorPages.includes("livestream-config"),
      "video"
    ),
    new NavItem(
      this.$i18n.t("rooms") as string,
      "room-config",
      this.moderatorPages.includes("rooms"),
      "door-open"
    ),
    new NavItem(
      this.$i18n.t("schedules") as string,
      "schedule-config",
      this.moderatorPages.includes("schedules"),
      "calendar"
    ),
    // new NavItem(
    //   this.$i18n.t("attachments") as string,
    //   "attachments-config",
    //   this.moderatorPages.includes("attachments-config"),
    //   "paperclip"
    // ),
    new NavItem(
      this.$i18n.t("users-and-support") as string,
      "",
      this.moderatorPages.includes("users-and-support"),
      "",
      ""
    ),
    new NavItem(
      this.$i18n.t("users") as string,
      "users",
      this.moderatorPages.includes("users"),
      "user"
    ),
    new NavItem(
      this.$i18n.t("user-roles") as string,
      "user-roles",
      this.moderatorPages.includes("user-roles"),
      "user"
    ),
    new NavItem(
      this.$i18n.t("registration") as string,
      "registration-config",
      this.moderatorPages.includes("registration-config"),
      "sign-in-alt",
      "registerForm"
    ),
    new NavItem(
      this.$i18n.t("notifications") as string,
      "notifications",
      this.moderatorPages.includes("notifications"),
      "bell"
    ),
    new NavItem(
      this.$i18n.t("statistics") as string,
      "statistics",
      this.moderatorPages.includes("statistics"),
      "user"
    ),
    new NavItem(
      this.$i18n.t("freshchat") as string,
      "freshchat-config",
      this.moderatorPages.includes("freshchat-config"),
      "headset"
    ),
    new NavItem(
      this.$i18n.t("mails") as string,
      "",
      this.moderatorPages.includes("mails"),
      "",
      ""
    ),
    new NavItem(
      this.$i18n.t("mail-templates") as string,
      "mails",
      this.moderatorPages.includes("mail-templates"),
      "envelope"
    ),
    new NavItem(
      this.$i18n.t("send-mail") as string,
      "sendMail",
      this.moderatorPages.includes("send-mail"),
      "paper-plane"
    ),
  ];

  get currentRoute() {
    return this.$route.name;
  }

  @featuresModule.Getter("all")
  features!: any;

  @stylingModule.Getter("logo")
  logo!: string;

  @authModule.Mutation("logout")
  clearData!: () => void;

  @authModule.Getter("currentUser")
  currentUser!: any;

  logout() {
    this.clearData();
    this.$router.push({ name: "admin-login" });
  }
}
