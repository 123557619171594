import SettingsService from "@/services/SettingsService";

const Freshchat = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: {all: any }) => state.all,
  },
  mutations: {
    SET_ALL(state: { all: any }, all: any) {
      state.all = all
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const freshchat = await SettingsService.fetchOnePublic("freshchat");
      commit("SET_ALL", freshchat.setting_value);
    },
    async update({ commit }: any, data: any) {
      await SettingsService.update("freshchat", data);
    }
  }
} as any

export default Freshchat