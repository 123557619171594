import { SocialPost } from "@/models/social-post.model";
import SocialPostsService from "@/services/SocialPostsService";

interface SocialPostState {
  lastPagination: any;
  all: any[];
}

const SocialPosts = {
  namespaced: true,
  state: {
    all: [],
    lastPagination: {},
  },
  getters: {
    all: (state: SocialPostState) => state.all,
  },
  mutations: {
    SET_ALL(state: SocialPostState, all: SocialPost[]) {
      state.all = all;
    },
    SET_PAGINATION(state: SocialPostState, pagination: any) {
      state.lastPagination = pagination;
    },
    ADD_RESULT(state: SocialPostState, all: any) {
      state.all = state.all.concat(all);
    },
    PREPEND_POST(state: SocialPostState, post: SocialPost) {
      state.all.unshift(post);
    },
    DELETE(state: SocialPostState, id: string) {
      const index = state.all.findIndex((p: SocialPost) => id === p.id);
      state.all.splice(index, 1);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const posts = await SocialPostsService.fetchAll();
      commit("SET_ALL", posts.data);
      commit("SET_PAGINATION", posts);
    },
    async fetchNext({ commit, state }: any) {
      const posts = await SocialPostsService.fetchCursor(
        state.lastPagination.next_page_url
      );
      commit("ADD_RESULT", posts.data);
      commit("SET_PAGINATION", posts);
    },
    async create({ commit }: any, payload: any) {
      const result = await SocialPostsService.create(payload);
      commit("PREPEND_POST", result);
    },
    async delete({ commit }: any, id: string) {
      await SocialPostsService.delete(id);
      commit("DELETE", id);
    },
  },
} as any;

export default SocialPosts;
