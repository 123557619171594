import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
const Livestream = () => import("@/views/Livestream.vue");
const Profile = () => import("@/views/Profile.vue");
const Admin = () => import("@/views/Admin.vue");
const Authentication = () => import("@/views/Authentication.vue");
const ComingSoon = () => import("@/views/ComingSoon.vue");
const MobileChat = () => import("@/views/MobileChat.vue");
const ZoomPlayer = () => import("@/components/video/ZoomPlayer.vue");
const CookiePrivacyDoc_NL = () =>
  import("@/components/GDPR/CookiePrivacyDoc_NL.vue");
const CookiePrivacyDoc_FR = () =>
  import("@/components/GDPR/CookiePrivacyDoc_FR.vue");
const CookiePrivacyDoc_EN = () =>
  import("@/components/GDPR/CookiePrivacyDoc_EN.vue");
const LanguagePick = () => import("@/views/LanguagePick.vue");
const SocialView = () => import("@/views/SocialView.vue");
const Dashboard = () => import("@/views/Dashboard.vue");
const StaticPage = () => import("@/views/StaticPage.vue");
const HomeConfig = () => import("@/components/admin/home/HomeConfig.vue");
const StyleConfig = () => import("@/components/admin/style/StyleConfig.vue");
const WherebyConfig = () =>
  import("@/components/admin/whereby/WherebyConfig.vue");
const FeatureConfig = () =>
  import("@/components/admin/features/FeatureConfig.vue");
const Notifications = () =>
  import("@/components/admin/notifications/Notifications.vue");
const Mails = () => import("@/components/admin/mails/MailTemplatesTable.vue");
const SendMail = () => import("@/components/admin/mails/SendMail.vue");
const Registration = () => import("@/components/Registration.vue");
const LivestreamConfig = () =>
  import("@/components/admin/livestreams/LivestreamConfig.vue");
const AdminLogin = () => import("@/views/AdminLogin.vue");
const Users = () => import("@/components/admin/users/Users.vue");
const RegistrationConfig = () =>
  import("@/components/admin/registration/RegistrationConfig.vue");
const UserDetail = () => import("@/components/admin/users/UserDetail.vue");
const LivestreamDetail = () =>
  import("@/components/admin/livestreams/LivestreamDetail.vue");
const StaticPages = () =>
  import("@/components/admin/static-pages/StaticPages.vue");
const StaticPagesDetail = () =>
  import("@/components/admin/static-pages/StaticPagesDetail.vue");
const HeaderConfig = () => import("@/components/admin/header/HeaderConfig.vue");
const FreshchatConfig = () =>
  import("@/components/admin/freshchat/FreshchatConfig.vue");
const TranslationsConfig = () =>
  import("@/components/admin/language/TranslationsConfig.vue");
const AttachmentsConfig = () =>
  import("@/components/admin/attachments/AttachmentsConfig.vue");
const SponsorsConfig = () =>
  import("@/components/admin/sponsors/SponsorsConfig.vue");
const AttachmentsDetail = () =>
  import("@/components/admin/attachments/AttachmentsDetail.vue");
const MailTemplateDetail = () =>
  import("@/components/admin/mails/MailTemplateDetail.vue");
const PasswordRequest = () => import("@/components/PasswordRequest.vue");
const UserRoles = () => import("@/components/admin/users/UserRoles.vue");
const Statistics = () => import("@/components/admin/users/Statistics.vue");
const BasicInfo = () => import("@/components/admin/BasicInfo.vue");
const Rooms = () => import("@/components/admin/rooms/Rooms.vue");
const RoomDetail = () => import("@/components/admin/rooms/RoomDetail.vue");
const RoomsOverview = () => import("@/views/RoomsOverview.vue");
const ScheduleConfig = () =>
  import("@/components/admin/schedule/ScheduleConfig.vue");
const ScheduleDetail = () =>
  import("@/components/admin/schedule/ScheduleDetail.vue");

import { authGuard } from "../auth0/authGuard";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [];

routes = [
  {
    path: "/languagepick",
    alias: ["/language"],
    name: "languagePick",
    meta: {
      authRequired: false,
    },
    component: LanguagePick,
  },
  {
    path: "/",
    alias: ["/home", "/dashboard"],
    name: "dashboard",
    component: Dashboard,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/livestream",
    name: "livestream",
    meta: {
      authRequired: true,
    },
    props: true,
    component: Livestream,
  },
  {
    path: "/social",
    name: "social",
    meta: {
      authRequired: true,
    },
    component: SocialView,
  },
  {
    path: "/rooms",
    name: "rooms",
    meta: {
      authRequired: true,
    },
    component: RoomsOverview,
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      authRequired: true,
    },
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      authRequired: true,
    },
    component: Admin,
    children: [
      {
        path: "login",
        name: "admin-login",
        component: AdminLogin,
      },
      {
        path: "basic-info",
        name: "basic-info",
        component: BasicInfo,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "home-config",
        name: "home-config",
        component: HomeConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "home-config",
        name: "home-config",
        component: HomeConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "livestream-config",
        name: "livestream-config",
        component: LivestreamConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "livestream-detail",
        name: "livestream-detail",
        component: LivestreamDetail,
        props: true,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "rooms",
        name: "room-config",
        component: Rooms,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "rooms/create",
        name: "room-create",
        component: RoomDetail,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "rooms/:id",
        name: "room-detail",
        component: RoomDetail,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "schedule-config",
        name: "schedule-config",
        component: ScheduleConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "schedule-detail",
        name: "schedule-detail",
        component: ScheduleDetail,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "style-config",
        name: "style-config",
        component: StyleConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "feature-config",
        name: "feature-config",
        component: FeatureConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "whereby-config",
        name: "whereby-config",
        component: WherebyConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "mails",
        name: "mails",
        component: Mails,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "send-mail",
        name: "sendMail",
        component: SendMail,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "user-detail",
        name: "user-detail",
        component: UserDetail,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "users",
        name: "users",
        component: Users,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "registration",
        name: "registration-config",
        component: RegistrationConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "static-pages",
        name: "static-pages",
        component: StaticPages,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "page-detail",
        name: "page-detail",
        component: StaticPagesDetail,
        props: true,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "header-config",
        name: "header-config",
        component: HeaderConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "freshchat-config",
        name: "freshchat-config",
        component: FreshchatConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "whereby-config",
        name: "whereby-config",
        component: WherebyConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "translations-config",
        name: "translations-config",
        component: TranslationsConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "attachments-config",
        name: "attachments-config",
        component: AttachmentsConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "attachments-detail",
        name: "attachments-detail",
        component: AttachmentsDetail,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "sponsors-config",
        name: "sponsors-config",
        component: SponsorsConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "mail-template-detail",
        name: "mail-template-detail",
        component: MailTemplateDetail,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "user-roles",
        name: "user-roles",
        component: UserRoles,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "statistics",
        name: "statistics",
        component: Statistics,
        meta: {
          sidebar: true,
        },
      },
    ],
  },
  {
    path: "/authentication",
    name: "authentication",
    component: Authentication,
  },
  {
    path: "/registration",
    name: "RegistrationForm",
    component: Registration,
    meta: {},
  },
  {
    path: "/password-request",
    name: "password-request",
    component: PasswordRequest,
  },
  {
    path: "/password-reset/:token",
    name: "password-reset",
    props: true,
    component: PasswordRequest,
  },
  {
    path: "/zoom",
    name: "zoom",
    component: ZoomPlayer,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/coming-soon",
    name: "comingSoon",
    component: ComingSoon,
  },
  {
    path: "/mobile",
    name: "mobileChat",
    component: MobileChat,
  },
  {
    path: "/cookie-privacy-nl",
    name: "cookie",
    component: CookiePrivacyDoc_NL,
  },
  {
    path: "/cookie-privacy-fr",
    name: "cookie",
    component: CookiePrivacyDoc_FR,
  },
  {
    path: "/cookie-privacy-us",
    name: "cookie",
    component: CookiePrivacyDoc_EN,
  },
  {
    path: "/social",
    name: "social",
    component: SocialView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/pages/:link",
    name: "static-page",
    props: true,
    component: StaticPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("Header/fetchAll");
  await store.dispatch("Languages/fetchAll");
  await store.dispatch("Features/fetchAll");
  await store.dispatch("Config/fetchAll");
  await store.dispatch("Styling/fetchAll");
  await store.dispatch("Auth/me");
  await store.dispatch("Freshchat/fetchAll");
  const loginRequired = store.getters["Features/loginRequired"];
  const authentication = store.getters["Features/all"].authentication?.type;
  const comingSoon = store.getters["Features/all"].comingSoon;
  const languagePick = store.getters["Features/all"].languagePick;
  const landingPage = store.getters["Features/all"].landingPage;
  const registrationRequired = store.getters["Features/all"].registerForm;
  const language = localStorage.getItem("lang");
  const isAdmin = store.getters["Auth/isAdmin"];
  const isModerator = store.getters["Auth/isModerator"];
  const isLoggedIn = store.getters["Auth/isLoggedIn"];
  const registrationCompleted = store.getters["Auth/registrationCompleted"];
  const preferredLivestream = store.getters["Auth/preferredLivestream"];

  // if(isLoggedIn) {
  //   await store.dispatch("IFlyChat/create")
  // }

  // console.log(
  //   loginRequired,
  //   isLoggedIn,
  //   registrationCompleted,
  //   registrationRequired,
  //   landingPage
  // );

  if (to.path == "/" && !landingPage) {
    if (preferredLivestream) {
      return next({ name: "livestream", query: { id: preferredLivestream } });
    }
    return next({ name: "livestream" });
  }

  if (to.name === "admin-login") {
    return next();
  }

  if (to.path == "/admin") {
    return next({ name: "feature-config" });
  }

  if (to.path.startsWith("/admin") && (isAdmin || isModerator)) {
    return next();
  }

  if (to.path.startsWith("/admin") && (!isAdmin || !isModerator)) {
    return next({ name: "admin-login" });
  }

  if (!loginRequired && to.name === "authentication") {
    return next({ name: "dashboard" });
  }

  if (!loginRequired || !to.meta?.authRequired) {
    return next();
  }

  if (
    !isAdmin &&
    isLoggedIn &&
    registrationRequired &&
    !registrationCompleted
  ) {
    return next({ name: "RegistrationForm" });
  }

  if (loginRequired && isLoggedIn) {
    return next();
  }

  if (authentication === "auth0") {
    authGuard(to, from, next);
  } else if (authentication !== "none") {
    return next({ name: "authentication" });
  }

  if (languagePick && to.path !== "/language" && !language) {
    return next({ name: "languagePick" });
  }

  if (comingSoon && to.name !== "coming-soon" && !isAdmin) {
    return next({ name: "coming-soon" });
  }

  if (to.path == "/coming-soon" && !comingSoon) {
    return next({ name: "home" });
  }

  return next();
});

export default router;
