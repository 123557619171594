import SettingsService from "@/services/SettingsService";

interface LanguagesState {
  all: { [key: string]: any };
}

const Languages = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: LanguagesState) => state.all,
  },
  mutations: {
    SET_ALL(state: LanguagesState, languages: any) {
      state.all = languages;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const languages = await SettingsService.fetchOnePublic("languages");
      commit("SET_ALL", languages.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update("languages", payload);
    },
  },
} as any;

export default Languages;
