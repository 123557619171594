import LogsService from "@/services/LogsService";

interface LogsState {
  all: { [key: string]: any };
}

const Logs = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: LogsState) => state.all,
  },
  mutations: {
    SET_ALL(state: LogsState, logs: any) {
      state.all = logs;
    },
  },
  actions: {
    async fetchAll({ commit }: any, email: string) {
      console.log(email);
      const logs = await LogsService.fetchOne(email);
      console.log(logs);
      commit("SET_ALL", logs);
    },
  },
} as any;

export default Logs;
