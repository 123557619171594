import { Room } from "@/models/room.model";
import RoomsService from "@/services/RoomsService";

interface RoomsState {
  all: Room[];
  current: Room;
}

const Rooms = {
  namespaced: true,
  state: {
    all: [],
    current: new Room("", ""),
  },
  getters: {
    all: (state: RoomsState) => state.all,
    current: (state: RoomsState) => state.current,
  },
  mutations: {
    SET_CURRENT(state: RoomsState, room: Room) {
      state.current = room;
    },
    SET_ALL(state: RoomsState, rooms: Room[]) {
      state.all = rooms;
    },
  },
  actions: {
    async fetchById({ commit }: any, id: string) {
      const room = await RoomsService.fetchOne(id);
      commit("SET_CURRENT", room);
    },
    async fetchAll({ commit }: any) {
      const livestreams = await RoomsService.fetchAll();
      commit("SET_ALL", livestreams);
    },
    async update({ commit }: any, data: any) {
      const room = await RoomsService.update(data.id, data);
    },
    async create({ commit }: any, data: any) {
      const room = await RoomsService.create(data);
    },
    async delete({ commit }: any, id: string) {
      const room = await RoomsService.delete(id);
    },
  },
} as any;

export default Rooms;
