import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class NotificationsService extends BaseService<any> {
  get endpoint() {
    return "/user_alerts";
  }
}

export default new NotificationsService();
