import SettingsService from "@/services/SettingsService";

interface RegistrationState {
  form: any;
}

const Styling = {
  namespaced: true,
  state: {
    form: [],
  },
  getters: {
    form: (state: RegistrationState) => state.form,
  },
  mutations: {
    SET_FORM(state: RegistrationState, form: any) {
      state.form = form || [];
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const styling = await SettingsService.fetchOne("registration");
      commit("SET_FORM", styling.setting_value);
    },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("registration", data);
    },
  },
} as any;

export default Styling;
