import SettingsService from "@/services/SettingsService";

interface SponsorsState {
  all: { [key: string]: any };
}

const Sponsors = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: SponsorsState) => state.all,
  },
  mutations: {
    SET_ALL(state: SponsorsState, sponsors: any) {
      state.all = sponsors;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const sponsors = await SettingsService.fetchOne("sponsors");
      commit("SET_ALL", sponsors.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update("sponsors", payload);
    },
  },
} as any;

export default Sponsors;
