import LivestreamService from "@/services/LivestreamService";
import SettingsService from "@/services/SettingsService";
import Vue from "vue";

const Livestreams = {
  namespaced: true,
  state: {
    livestreams: [],
    livestream: {},
    active_livestream: {},
  },
  getters: {
    allLivestreams: (state: { livestreams: any }) => state.livestreams,
    livestream: (state: { livestream: any }) => state.livestream,
    active: (state: { livestreams: any; active_livestream }) => {
      return state.active_livestream;
    },
  },
  mutations: {
    SET_CURRENT(state: { livestream: any }, livestream: any) {
      state.livestream = livestream;
    },
    SET_ALL(state: { livestreams: any }, livestreams: any) {
      Vue.set(state, "livestreams", livestreams);
    },
    SET_ACTIVE(state: { active_livestream: any }, active_livestream: any) {
      Vue.set(state, "active_livestream", active_livestream);
    },
    EMPTY_CURRENT(state: { livestream: any }) {
      state.livestream = {
        id: "",
        active: false,
        inactiveText: {
          us: "",
          nl: "" 
        },
        code: {
          en: "",
          nl: "",
        },
        type: {
          en: "",
          nl: "",
        },
        title: {
          en: "",
          nl: "",
        },
        description: {
          en: "",
          nl: "",
      },
        hostRoomUrl: {},
        roomKey: {},
        roomMode: {},
        api_key: "",
        api_secret: "",
        leaveUrl: "",
        password: "",
        lang: "",
        schedule: {
          active: false,
          selected: ""
        }, 
        chat: {
          active: false,
          type: {
              us: "",
              nl: ""
          },
          code: {
              us: "",
              nl: ""
          },
          roomCode: {
            us: "",
            nl: ""
          }
        },
        speakers: {
          active: false,
          nl: [
            {
              avatar: "",
              name: "",
              description: "",
              url: ""
            }
          ],
          us: [
            {
              avatar: "",
              name: "",
              description: "",
              url: ""
            }
          ]
        },
        blocks: []
      };
    },
  },
  actions: {
    async fetchById({ commit }: any, id: string) {
      const livestream = await LivestreamService.fetchOne(id);
      console.log(livestream);
      commit("SET_CURRENT", livestream);
    },
    async fetchAll({ commit }: any) {
      const livestreams = await LivestreamService.fetchAllUnpaginated();
      commit("SET_ALL", livestreams);
      commit(
        "SET_ACTIVE",
        livestreams.find((livestream) => livestream.active === true)
      );
      return livestreams;
    },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("livestream", data);
    },
  },
} as any;

export default Livestreams;
