import axios, { AxiosRequestConfig } from "axios";
import store from "@/store";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = store.getters["Auth/token"];

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.common["Accept"] = "application/json";
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default http;
